.main-navigation {
  background: #794EC1;
  @include shadow;
}

.dropdown-menu.show {
  left: -7.7rem;
}

.nav-link-root {
  color: #ffffff
   !important;
}

.nav-link-item {
  color: #ffffff;
  margin-right: 20px;
}
